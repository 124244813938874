<template>
  <main>
    <MicromarkingShema />
    <SectionHero :variant="hero.variant">
      <OrganismHero :hero="hero" />
      <template #footerShort>
        <div class="flex flex-col md:flex-row flex-1 md:items-end h-full md:pb-6">
          <div class="flex flex-1">
            <TownDropdown :items="cityItems" @select="onCitySelect" />
          </div>
          <OrganismTabs :list="tabs" />
          <AtomButtonDropdown
            class="md:hidden w-full mt-3"
            :auto-width="true"
            :theme-light="true"
            :items="tabsDropdown"
            :initial-index="initialView"
            @select="onViewSelect"
          />
        </div>
      </template>
    </SectionHero>
    <SectionDefault>
      <NuxtChild :context="{ cityId: cityId }" />
    </SectionDefault>
  </main>
</template>

<script>
import AtomButtonDropdown from '../../components/atoms/AtomButtonDropdown'
import TownDropdown from '../../components/molecules/heroControls/TownsDropdown'
import OrganismHero from '../../components/organisms/layouts/OrganismHero'
import OrganismTabs from '../../components/organisms/layouts/OrganismTabs'
import SectionDefault from '../../components/templates/layouts/SectionDefault'
import SectionHero from '../../components/templates/layouts/SectionHero'
import MicromarkingShema from '@/components/MicromarkingShema'

export default {
  name: 'ContactsMain',
  components: {
    TownDropdown,
    OrganismTabs,
    SectionDefault,
    SectionHero,
    OrganismHero,
    AtomButtonDropdown,
    MicromarkingShema,
  },
  meta: {
    theme: 'dark',
  },
  data: c => {
    return {
      cityId: null,
      hero: {
        title1: 'Контакты',
        title2: 'клубов',
        breadcrumbs: c.$breadcrumbs.contacts(),
      },
      tabsDropdown: [
        { text: 'Списком', to: '/contacts' },
        { text: 'На карте', to: '/contacts/map' },
      ],
      tabs: [
        {
          title: 'На карте',
          to: '/contacts/map',
        },
        {
          title: 'Список',
          to: '/contacts',
        },
      ],
    }
  },
  computed: {
    cityItems() {
      return this.$store.getters['modules/content/getClubsTownsAsDropdownWithAll']
    },
    initialView() {
      return this.$route.name === 'contacts_map' ? 1 : 0
    },
  },
  methods: {
    onCitySelect(cityId) {
      this.cityId = cityId
    },
    onViewSelect(i) {
      const view = this.tabsDropdown[i]
      this.$router.push(view.to)
    },
  },
}
</script>
